import React from "react";
import { graphql } from "gatsby";
import { type BuscaExamePageProps } from "_types/IBuscaExamePage";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import {
  getDataSearchMenu,
  getDataSpecialties,
  getDataCTAContato,
  getDataSectionQualityWarranty,
  getDataMenu,
  getDataFooter
} from "_utils/assemblePagesData";
import { ListSearchExams } from "presentation/screens";
import { WEBSITE_URL } from "_utils/constants";
import SEO from "presentation/Seo";

function BuscaExamePage({ location, data }: BuscaExamePageProps) {
  const searchParams = new URLSearchParams(location.search);
  const queryTerm = searchParams.get("q");
  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });

  const specialties = getDataSpecialties({
    specialties: data?.specialties
  });
  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });
  const ctaContato = getDataCTAContato({ ctaContato: data?.ctaContato });
  const qualityWarranty = getDataSectionQualityWarranty({
    sectionQuality: data?.sectionQuality
  });
  const sectionSearch = {
    allExams: data?.allExams,
    term: queryTerm ?? "",
    urlSearchExam: menu?.link_busca_exames?.uid,
    title:
      data?.searchExams?.data?.body[0].primary?.titulo_busca_exames?.richText,
    description:
      data?.searchExams?.data?.body[0].primary?.sub_titulo_busca_exames
        ?.richText
  };
  const footerData = getDataFooter({ data: data?.menu?.data?.body });

  const dataSEO = {
    canonicalUrl: `${WEBSITE_URL}${data?.searchExams?.data?.canonical_url?.text}`,
    noFollow: data?.searchExams?.data?.no_follow,
    noIndex: data?.searchExams?.data?.no_index,
    openGraph: data?.searchExams?.data?.open_graph[0],
    telefone1: data?.contact?.data?.telefone_1?.text,
    email: data?.contact?.data?.email?.text,
    imagesPage: [],
    metaTags: [
      {
        property: "application-name",
        content: "GatsbySeo"
      },
      {
        property: "og:title",
        content:
          data?.searchExams?.data?.open_graph[0]?.og_title?.text ??
          "sem conteúdo"
      },
      {
        property: "og:url",
        content: `${WEBSITE_URL}${data?.searchExams?.uid}`
      },
      {
        property: "og:image",
        content: data?.searchExams?.data?.open_graph[0]?.og_image?.url
      },
      {
        property: "og:type",
        content: "website"
      }
    ]
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        footerData={footerData}
        contact={data?.contact}
      >
        <ListSearchExams
          ctaContato={ctaContato}
          sectionQualityWarranty={qualityWarranty}
          sectionSearch={sectionSearch}
        />
      </TemplatePage>
    </ThemeProvider>
  );
}

export const query = graphql`
  query GetDataSearchExams {
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    contact: prismicContato {
      ...Contact
    }
    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          nome_da_especialidade {
            richText
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }
    searchExams: prismicBuscarExames {
      uid
      data {
        open_graph {
          og_url {
            text
          }
          og_title {
            text
          }
          og_image {
            url
          }
          og_description {
            text
          }
        }
        canonical_url {
          text
        }
        body {
          ... on PrismicBuscarExamesDataBodyBusca {
            id
            primary {
              titulo_busca_exames {
                richText
              }
              sub_titulo_busca_exames {
                richText
              }
              titulo_do_exame_destaque {
                richText
              }
            }
            items {
              adicionar_exames {
                url
                id
                uid
                document {
                  ... on PrismicExames {
                    id
                    data {
                      body {
                        ... on PrismicExamesDataBodyHero {
                          id
                          primary {
                            codigo_sollutio {
                              text
                            }
                            nome_do_exame {
                              text
                            }
                            codigo_tuss {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ctaContato: prismicCtaContato {
      ...CTAContatoFragment
    }
    sectionQuality: prismicQualidade {
      ...QualityFragment
    }
    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`;

export default BuscaExamePage;
